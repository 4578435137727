
.badge.bg-danger 
{
    background: var(--bs-danger)!important;
}

.badge.bg-success 
{
    background: var(--bs-success)!important;
}

.mdi.mdi-ethernet.h3.text-danger
{
    color: var(--bs-danger)!important;
}

.mdi.mdi-ethernet.h3.text-success
{
    color: var(--bs-success)!important;
}

#column-8
{
    display: grid;
    grid-template-columns: repeat(8,1fr);
    gap: 5px!important;

    .col 
    {
        display: block;
        margin: 0;
        padding: 0;

        div 
        {
            padding-left: 0!important;
            padding-right: 0!important;
            font-size: 12px;
        }
    }

    .status-active 
    {
        cursor: pointer;
    }

    // на десктопі показуємо повну назву
    .onu-name
    {
        display: inline;
    }
    
    // на мобільному показуємо тільки число
    .onu-mobile-name
    {
        display: none;
    }
}

// TODO: колонки в грід і переміщаємо на мобільному
// 6 column

@media(max-width:776px)
{
    #column-8
    {

        // ховаємо іконку на девайсах
        i
        {
            display: none;
        }

        .status-active
        {
            background-color: var(--bs-success)!important;
            // color: var(--bs-white)!important;
            color: var(--bs-gray-100)!important;
        }

        .status-not-active
        {
            background-color: var(--bs-red)!important;
            // color: var(--bs-white)!important;
            color: var(--bs-gray-100)!important;
        }

        .onu-wrapper
        {
            text-align: center;
        }

        .onu-name
        {
            display: none;
        }
        
        .onu-mobile-name
        {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-flow: column;
            line-height: 1;
        }

        .onu-mobile-name b 
        {
            text-transform: uppercase;
            display: block;
            margin: 0 0 5px;
        }
    }
}

// стисла статистика тільки у портів!
.mobile-stats 
{
    display: none;
}

// остання колонка
#onu-columns
{

    th:nth-of-type(6),
    td:nth-of-type(6)
    {
        max-width: 104px;
        overflow: hidden;
    }

    // назва півжирним зі скороченою назвою -- має виводитися на мобільному
    .onu-name 
    {
        display: inline;
    }

    .onu-name-mobile
    {
        display: none;
    }

    td 
    {

        // уже не використовується
/*         textarea 
        {
            margin-top: 5px;
            margin-bottom: 5px;
            padding: 5px;
        } */

        // тепер просто вивід примітки
        .onu-notice 
        {
            white-space: normal;
            line-height: 1.1;
            display: inline-block;
        }
    }

    // colors xPower
    .red 
    {
        color: var(--bs-danger)
    }

    .yellow 
    {
        color: var(--bs-yellow)
    }

    .green 
    {
        color: var(--bs-success)
    }

    .white 
    {
        color: var(--bs-white)
    }

}

@media(max-width:776px)
{

    #onu-columns
    {
        margin: 0 -10px;

        // стандарнтий тхед ховаємо
        thead
        {
            display: none;
        }

        // коротку статистику показуємо
        .mobile-stats
        {
            display: block;
        }

        .mobile-stats tr
        {
            display: flex;
            justify-content: space-between;
            padding: 5px;
        }

        // справа
        .mobile-stats .quantity
        {
            display: flex;
            gap: 5px;
        }

        .mobile-stats th 
        {
            border: none;
            padding: 5px;
        }

        .mobile-stats .quantity span:first-child::before
        {
            content: 'Всього: ';
        }

        // status on
        .mobile-stats .text-success
        {
            color: var(--bs-success)!important;
        }

        .mobile-stats .text-success::after
        {
            content: ' on';
        }

        // status off
        .mobile-stats .text-danger
        {
            color: var(--bs-danger)!important;
        }

        .mobile-stats .text-danger::after 
        {
            content: ' off';
        }

        tr 
        {
            display: grid;
            grid-template-columns: 80px 1.9fr 1fr;
            grid-template-areas: 
                'onu disconnect disconnect'
                'onu notice laser'
                'onu notice mac';
            grid-template-rows: auto repeat(2, 1fr);
            border-top: 1px solid rgba($gray-600, 0.2);

            td 
            {
                border: none;
            }

            td br 
            {
                display: none;
            }

            .disconnect 
            {
                display: flex;
                justify-content: space-between;
                margin: 0 0 -5px;
            }

            td:nth-of-type(1)
            {
                grid-area: disconnect;

                padding: 0;

                // min-height: 18px;
                white-space: normal;
            }

            td:nth-of-type(2)
            {
                grid-area: onu;

                padding: 0;

                a 
                {
                    display: flex;
                    height: 100%;
                }

                .onu-name-mobile 
                {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-flow: column;

                    color: var(--bs-white);
                    padding: 0 5px;
                    width: 100%;
                    line-height: 1;   
                }

                    .onu-name-mobile.bg-success 
                    {
                        background: var(--bs-success)!important;
                        color: var(--bs-gray-100);
                    }

                    .onu-name-mobile.bg-danger
                    {
                        background: var(--bs-danger)!important;
                        color: var(--bs-gray-100);
                    }

                    .onu-name-mobile b 
                    {
                        text-transform: uppercase;
                        margin: 0 0 5px;
                    }
                
                .onu-name{
                    display: none;
                }
            }

            // ховаємо пімпочку
            .badge
            {
                display: none;
            }
            
            // mac address
            td:nth-of-type(3)
            {
                grid-area: mac;

                text-align: right;
                padding: 0;
                font-size: 11px;
            }

            // примітка -- textarea
            td:nth-of-type(4)
            {
                grid-area: notice;

                padding: 0;
            }

            td:nth-of-type(5)
            {
                grid-area: laser;

                text-align: right;
                padding: 5px 0 0;
                color: var(--bs-white);
            }

            // стан портів
            td:nth-of-type(6)
            {
                display: none;
            }
        }
    }

    #onu-columns tr:first-child
    {
        border-top: none;
    }

    @media(max-width:500px)
    {
        #onu-columns tr
        {
            grid-template-columns: 50px 1.9fr 1fr;
            gap: 0 5px;
        }
    }
}

// група з 3 кнопок 
.flex-buttons
{
    display: flex;
    // flex-wrap: wrap;
    gap: 5px;
    margin: 0 -10px;
}

.flex-buttons button 
{
    margin: 0!important;
    padding: 5px!important;
    width: 33.333333%;
}

// modal window
.fade.modal.show
{
    display: flex!important;
    align-items: center;
    justify-content: center;
}

/* /////////////////
// шаблон об'єкту //
// Object.js ///////
///////////////// */

.onu-notice
{
    display: flex;
    gap: 5px;
    flex-flow: column;
    //margin: 0 -10px;

    textarea 
    {
        display: block;
        margin: 0 0 5px;
        min-height: 80px;
    }

    button 
    {
        margin-right: 0!important;
    }
}

